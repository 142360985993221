<template>
  <apexchart
    ref="chartRef"
    height="350"
    :series="series"
    :options="chartOptions"
  ></apexchart>
</template>

<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "FanbaseMultiAxisLineChartV2",
  props: {
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    strokeWidth: {
      type: Array,
      default: () => [],
    },
    dashArray: {
      type: Array,
      default: () => [],
    },
    strokeArray: {
      type: Array,
      default: () => [],
    },
    maxValue: {
      type: Number,
      required: true,
    },
    listenersMaxCount: {
      type: Number,
      required: true,
    },
    chartYAxis: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const fontSize = ref("12px");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const chartRef = ref(null);
    const chartOptions = {
      chart: {
        height: 350,
        fontFamily: "inherit",
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {},
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      stroke: {
        width: props.strokeArray,
        curve: "smooth",
        show: true,
        dashArray: props.dashArray,
      },
      legend: {
        show: true,
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      markers: {
        strokeWidth: 3,
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: props.categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 30,
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: props.chartYAxis,
      tooltip: {
        style: {
          fontSize: fontSize.value,
        },
        y: {
          formatter: function (value) {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };
    return { chartOptions, chartRef };
  },
};
</script>

<style scoped></style>
