<template>
  <div class="d-flex flex-column justify-content-between align-items-end h-100">
    <table class="table table-row-gray-300 gy-7 text-center">
      <thead>
        <tr
          :class="[
            'fw-bold fs-6t ext-gray-800 border-bottom border-gray-200',
            tableStyling ? 'table-header-v2' : '',
          ]"
        >
          <th v-for="(header, index) in tableHeaders" :key="index">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in list" :key="index" class="d-flex">
          <tr :class="tableStyling ? 'table-data-text' : ''">
            <td>{{ item.rank ? item.rank : "N/A" }}</td>

            <td>{{ item.country ? item.country : "N/A" }}</td>

            <td>{{ item.followers ? item.followers : "N/A" }}</td>
          </tr>
        </template>
      </tbody>
    </table>
    <el-pagination
      background
      class="mb-3"
      :hide-on-single-page="true"
      :page-size="10"
      :small="true"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="paginate"
      :total="noOfEntries"
    ></el-pagination>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { checkIsValueAvailableOrNot } from "@/utils/staticHelper";
export default defineComponent({
  name: "Listeners Table",
  components: {},
  props: {
    widgetClasses: String,
    data: {
      type: Object,
      required: true,
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableStyling: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const noOfEntries = ref(props.data.countries.length);
    const list = ref([]);
    let startingIndex = 0;
    let endingIndex =
      props.data.countries.length > 9 ? 9 : props.data.countries.length - 1;
    let prevPage = 1;

    onMounted(() => {
      paginate(1);
    });

    const paginate = (currentPage) => {
      list.value = [];
      const multiplier = (currentPage - prevPage) * 10;
      prevPage = currentPage;
      startingIndex += multiplier;
      endingIndex += multiplier;
      let index = 0;
      for (let i = startingIndex; i <= endingIndex; i++) {
        if (i === noOfEntries.value) {
          break;
        }
        list.value[index] = {
          rank: i + 1,
          country: props.data.countries[i],
          followers: checkIsValueAvailableOrNot(
            "key",
            null,
            props.data.followers[i]
          ),
        };
        index++;
      }
    };
    return {
      list,
      noOfEntries,
      paginate,
    };
  },
});
</script>
<style lang="scss" scoped>
.table.gy-7 th,
.table.gy-7 td {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.table-header-v2 {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #33647f !important;
}
</style>
