<template>
  <EmptyCardLoading
    :loading="loading"
    :type="'skeleton'"
    v-if="loading"
    :card-height="'400px'"
    :rows="9"
  />
  <CardToolbar
    v-if="series.length > 0 && categories.length > 0 && !loading"
    :show-toolbar-buttons="false"
    :description="null"
    :title="'BlingRock Score'"
    :fontStyle="true"
    :margin="false"
    :padding="false"
    :padding-left="false"
    :shadow-class="true"
  >
    <template v-slot:body>
      <ScoreChartV2
        :stroke="stroke"
        :chart-series="series"
        :categories="categories"
        :show-label="false"
        :show-yaxis-title="'score'"
        :show-xaxis-title="'date'"
      />
    </template>
  </CardToolbar>
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import ScoreChartV2 from "@/artists/components/ScoreChartV2";
import EmptyCardLoading from "@/common/components/EmptyCardLoading";
import { onMounted, ref } from "vue";
import { getBlingRockScore } from "@/api/mongoDB";
import { useRoute } from "vue-router";

export default {
  name: "BlingRockScore",
  components: {
    CardToolbar,
    ScoreChartV2,
    EmptyCardLoading,
  },
  setup() {
    const loading = ref(false);
    const series = ref([]);
    const categories = ref([]);
    const stroke = ref({
      width: [3, 1],
      dashArray: [0, 8],
      curve: "smooth",
    });
    const route = useRoute();
    onMounted(async () => {
      try {
        loading.value = true;
        if (route.params.spotifyID) {
          let { data } = await getBlingRockScore(route.params.spotifyID);
          series.value.push(
            { name: "Score", type: "line", data: data.yAxis },
            { name: "Trend", type: "line", data: data.trends }
          );
          categories.value = data.xAxis;
          data = {};
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    });
    return { loading, series, categories, stroke };
  },
};
</script>
