<template>
  <div class="mt-2 artist-social-media" id="social_media_v2">
    <div id="growth_overtime">
      <FanbaseOvertimeV2
        :title="'Fanbase Growth Overtime'"
        :description="'Artist fanbase growth'"
        :platforms="growthPlatforms"
      />
    </div>
    <div id="fanbase_engagement_overtime">
      <FanbaseGrowthV2
        :title="'Fanbase Engagement Overtime'"
        :description="'Artist fanbase engagement'"
        :platforms="engagementPlatforms"
        :trend-line="false"
        :y-axis-opposite="true"
        :column-lg="12"
        :show-toolbar-buttons="false"
        margin-class="margin-bottom-12"
      />
    </div>
    <div id="fanbase_distribution">
      <FanbaseDistributionV2 />
    </div>
    <div id="fanbase_growth">
      <FanbaseGrowthV2
        :platforms="growthPlatforms"
        :title="'Fanbase Growth'"
        :description="'The size of Artist\'s fanbase and distribution across social media and music channels'"
        :trend-line="true"
        :y-axis-opposite="false"
        :column-lg="8"
        :show-toolbar-buttons="true"
        margin-class="mb-5 mb-xl-10"
      />
    </div>
    <!--    <div id="weekly_post_frequency">-->
    <!--      <WeeklyPostFrequencyV2 />-->
    <!--    </div>-->
  </div>
</template>
<script>
import FanbaseDistributionV2 from "@/artists/components/FanbaseDistributionV2.vue";
import FanbaseOvertimeV2 from "./FanbaseOvertimeV2.vue";
import FanbaseGrowthV2 from "./FanbaseGrowthV2.vue";
import { ref } from "vue";
// import WeeklyPostFrequencyV2 from "@/artists/components/WeeklyPostFrequencyV2";

export default {
  name: "SocialMediaV2",
  components: {
    FanbaseDistributionV2,
    FanbaseOvertimeV2,
    FanbaseGrowthV2,
    // WeeklyPostFrequencyV2,
  },
  setup() {
    const growthPlatforms = ref([
      {
        id: 1,
        name: "spotify",
        apiKeyIndicator: "spotify",
        indicator: "Followers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 2,
        name: "instagram",
        apiKeyIndicator: "instagram",
        indicator: "Followers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 3,
        name: "soundcloud",
        apiKeyIndicator: "soundcloud",
        indicator: "Followers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 4,
        name: "twitter",
        apiKeyIndicator: "twitter",
        indicator: "Followers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 5,
        name: "tiktok",
        apiKeyIndicator: "tiktok",
        indicator: "Followers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 6,
        name: "youtube",
        apiKeyIndicator: "youtube_channel",
        indicator: "Subscribers",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 7,
        name: "deezer",
        apiKeyIndicator: "deezer",
        indicator: "Fans",
        dataFound: false,
        categories: [],
        series: [],
      },
    ]);
    const engagementPlatforms = ref([
      {
        id: 1,
        name: "facebook",
        apiKeyIndicator: "facebook",
        indicator: "Likes",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 2,
        name: "facebook",
        apiKeyIndicator: "facebook",
        indicator: "Talks",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 3,
        name: "twitter",
        apiKeyIndicator: "twitter",
        indicator: "Retweets",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 4,
        name: "youtube",
        apiKeyIndicator: "youtube_channel",
        indicator: "Views",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 5,
        name: "tiktok",
        apiKeyIndicator: "tiktok",
        indicator: "Likes",
        dataFound: false,
        categories: [],
        series: [],
      },
      {
        id: 6,
        name: "line",
        apiKeyIndicator: "line",
        indicator: "Likes",
        dataFound: false,
        categories: [],
        series: [],
      },
    ]);
    return { growthPlatforms, engagementPlatforms };
  },
};
</script>
