<template>
  <div class="margin-bottom-12">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'450px'"
      :rows="10"
    />
    <CardToolbar
      :description="description"
      :title="title"
      :show-toolbar-buttons="true"
      :margin="false"
      :font-style="true"
      :padding-left="true"
      :shadow-class="true"
      v-if="chartSeries.length > 0 && chartCategories.length > 0 && !loading"
    >
      <template v-slot:toolbar>
        <button
          class="btn btn-sm btn-color-muted btn-active btn-active-primary"
          @click="changeYearValue(1)"
          :class="[{ active: years === 1 }]"
        >
          One Year
        </button>
        <button
          class="btn btn-sm btn-color-muted btn-active btn-active-primary"
          @click="changeYearValue(2)"
          :class="[{ active: years === 2 }]"
        >
          Two Years
        </button>
        <button
          class="btn btn-sm btn-color-muted btn-active btn-active-primary"
          @click="changeYearValue(3)"
          :class="[{ active: years === 3 }]"
        >
          Three Years
        </button>
      </template>
      <template v-slot:body>
        <FanbaseMultiAxisLineChartV2
          :series="chartSeries"
          :categories="chartCategories"
          :currency-values="false"
          :dash-array="dashArray"
          :stroke-array="strokeArray"
          :max-value="Math.max(...maxArray)"
          :listeners-max-count="listenersMaxCount"
          :chart-y-axis="chartYAxis"
        />
      </template>
    </CardToolbar>
  </div>
</template>

<script>
import { ref } from "vue";
import CardToolbar from "../../common/components/CardToolbar";
import { getFanmetricSourcesBySpotifyID } from "@/api/mongoDB";
import FanbaseMultiAxisLineChartV2 from "../../common/components/FanbaseMultiAxisLineChartV2.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading";
import * as moment from "moment";
import { useRoute } from "vue-router";
import { onMounted } from "vue";
import {
  capitalizeFirstLetter,
  formatNumberIntoHumanizeForm,
  getPlatformColor,
} from "@/utils/staticHelper";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default {
  name: "FanbaseOvertimeV2",
  components: { CardToolbar, FanbaseMultiAxisLineChartV2, EmptyCardLoading },
  props: {
    showDeSelectButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Fanbase Growth Overtime",
    },
    description: {
      type: String,
      default: "Artist fanbase growth",
    },
    platforms: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const chartYAxis = ref([]);
    const chartSeries = ref([]);
    const chartCategories = ref([]);
    const maxArray = ref([]);
    const listeners = ref([]);
    const listenersMaxCount = ref(0);
    const dashArray = ref([]);
    const strokeArray = ref([]);
    const loading = ref(false);
    const years = ref(3);
    const spotifyListeners = ref([]);
    onMounted(async () => {
      if (route.params.spotifyID) {
        await getSpotifyListeners();
      }
    });
    const resetData = () => {
      chartSeries.value = [];
      chartCategories.value = [];
      listeners.value = [];
      chartYAxis.value = [];
    };

    const getSpotifyListeners = async () => {
      try {
        const { data } = await getFanmetricSourcesBySpotifyID(
          route.params.spotifyID,
          "listeners",
          "spotify",
          years.value,
          1
        );
        spotifyListeners.value = data;
      } catch (e) {
        console.log(e);
      } finally {
        await getFanbaseData();
      }
    };

    const getFanbaseData = async () => {
      try {
        loading.value = true;
        resetData();
        let promises = [];
        Object.values(props.platforms).forEach((platform) => {
          promises.push(
            getFanmetricSourcesBySpotifyID(
              route.params.spotifyID,
              platform.indicator.toLowerCase(),
              platform.apiKeyIndicator,
              years.value,
              1
            ).then(({ data }) => {
              const yAxis = [];
              let follower = 0;
              spotifyListeners.value.map((listener) => {
                const res = data.find((item) => {
                  return (
                    moment(listener.timestp).format("MMM YY") ===
                    moment(item.timestp).format("MMM YY")
                  );
                });
                if (platform.id === 1) {
                  listeners.value.push(listener.value);
                  chartCategories.value.push(
                    moment(listener.timestp).format("MMM YY")
                  );
                }
                if (res) {
                  yAxis.push(res.value);
                  follower = res.value;
                } else {
                  yAxis.push(follower);
                }
              });
              dashArray.value.push(0);
              strokeArray.value.push(3);
              const object = {
                id: platform.id,
                data: yAxis,
                name:
                  capitalizeFirstLetter(platform.name) +
                  " " +
                  platform.indicator,
                type: "line",
                color: getPlatformColor(platform.name),
              };
              maxArray.value.push(Math.max(...yAxis));
              chartSeries.value.push(object);
              chartSeries.value.sort((a, b) => a.id - b.id);
              if (platform.id === 1) {
                chartYAxis.value.push({
                  id: platform.id,
                  seriesName:
                    capitalizeFirstLetter(props.platforms[0].name) +
                    " " +
                    props.platforms[0].indicator,
                  opposite: false,
                  forceNiceScale: true,
                  show: true,
                  title: {
                    text: "Fanbase",
                    style: {
                      color: labelColor,
                      fontSize: "12px",
                      fontWeight: 400,
                    },
                  },
                  labels: {
                    formatter: (value) => {
                      return formatNumberIntoHumanizeForm(value);
                    },
                    style: {
                      colors: labelColor,
                      fontSize: "12px",
                    },
                  },
                });
              } else {
                chartYAxis.value.push({
                  id: platform.id,
                  seriesName:
                    capitalizeFirstLetter(props.platforms[0].name) +
                    " " +
                    props.platforms[0].indicator,
                  show: false,
                });
              }
            })
          );
        });
        await Promise.all(promises);
        chartYAxis.value.sort((a, b) => a.id - b.id);
        chartYAxis.value[0].max = Math.max(...maxArray.value);
      } catch (e) {
        console.error(e);
      } finally {
        const spotifyListenersObject = {
          data: listeners.value,
          name: "Spotify Listeners",
          type: "line",
          color: getPlatformColor("spotify"),
        };

        listenersMaxCount.value = Math.max(...spotifyListenersObject.data);
        chartSeries.value.push(spotifyListenersObject);
        chartYAxis.value.push({
          forceNiceScale: true,
          show: true,
          min: 0,
          max: listenersMaxCount.value,
          opposite: true,
          title: {
            text: "Spotify Listeners",
            style: {
              color: labelColor,
              fontSize: "12px",
              fontWeight: 400,
            },
          },
          labels: {
            formatter: (value) => {
              return formatNumberIntoHumanizeForm(value);
            },
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        });
        dashArray.value.push(8);
        strokeArray.value.push(1);
        loading.value = false;
      }
    };
    const changeYearValue = async (value) => {
      years.value = value;
      await getSpotifyListeners();
    };

    return {
      chartSeries,
      chartCategories,
      dashArray,
      strokeArray,
      loading,
      maxArray,
      listenersMaxCount,
      chartYAxis,
      years,
      changeYearValue,
    };
  },
};
</script>

<style scoped></style>
