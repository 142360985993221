<template>
  <EmptyCardLoading
    :loading="loading"
    :type="'skeleton'"
    v-if="loading"
    :card-height="'450px'"
    :rows="10"
    class="mb-5 mb-xl-10"
  />
  <CardToolbar
    :description="description"
    :show-toolbar-buttons="showToolbarButtons"
    :title="title"
    :font-style="true"
    :shadow-class="true"
    v-if="graphPlatforms.length > 0 && !loading"
    :margin-class="marginClass"
  >
    <template v-slot:toolbar>
      <button
        class="btn btn-sm btn-color-muted btn-active btn-active-primary"
        @click="changeYearValue(1)"
        :class="[{ active: years === 1 }]"
      >
        One Year
      </button>
      <button
        class="btn btn-sm btn-color-muted btn-active btn-active-primary"
        @click="changeYearValue(2)"
        :class="[{ active: years === 2 }]"
      >
        Two Years
      </button>
      <button
        class="btn btn-sm btn-color-muted btn-active btn-active-primary"
        @click="changeYearValue(3)"
        :class="[{ active: years === 3 }]"
      >
        Three Years
      </button>
    </template>
    <template v-slot:body>
      <el-skeleton v-if="loading" :rows="20" animated />
      <el-row :gutter="16" v-if="!loading">
        <el-col
          v-for="platform in graphPlatforms"
          :key="platform"
          :xs="12"
          :md="12"
          :lg="columnLg"
        >
          <div class="p-2">
            <FanbaseLineChartV2
              :platform="platform"
              :y-axis-opposite="yAxisOpposite"
            />
          </div>
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>
<script>
import FanbaseLineChartV2 from "./FanbaseLineChartV2.vue";
import CardToolbar from "../../common/components/CardToolbar";
import { ref, onMounted } from "vue";
import { getFanmetricSourcesBySpotifyID } from "@/api/mongoDB";
import { useRoute } from "vue-router";
import EmptyCardLoading from "@/common/components/EmptyCardLoading";
import moment from "moment";
import createTrend from "trendline";
import {
  capitalizeEveryWordFirstLetter,
  getPlatformColor,
} from "@/utils/staticHelper";

export default {
  components: { FanbaseLineChartV2, CardToolbar, EmptyCardLoading },
  props: {
    platforms: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    trendLine: {
      type: Boolean,
      default: true,
    },
    yAxisOpposite: {
      type: Boolean,
      default: false,
    },
    columnLg: {
      type: Number,
      default: 8,
    },
    showToolbarButtons: {
      type: Boolean,
      default: true,
    },
    marginClass: {
      type: String,
      default: "mb-5 mb-xl-10",
    },
  },
  setup(props) {
    const loading = ref(false);
    const years = ref(3);
    const graphPlatforms = ref([]);
    const route = useRoute();
    const spotifyListeners = ref([]);
    onMounted(async () => {
      await getSpotifyListeners();
    });
    const filterPlatforms = () => {
      graphPlatforms.value = props.platforms.filter(
        (platform) => platform.dataFound === true
      );
    };
    const getSpotifyListeners = async () => {
      try {
        const { data } = await getFanmetricSourcesBySpotifyID(
          route.params.spotifyID,
          "listeners",
          "spotify",
          3,
          1
        );
        spotifyListeners.value = data;
      } catch (e) {
        console.log(e);
      } finally {
        await fetchGraphData();
      }
    };
    const fetchGraphData = async () => {
      if (route.params.spotifyID) {
        try {
          loading.value = true;
          let promises = [];
          Object.values(props.platforms).forEach((platform) => {
            promises.push(
              getFanmetricSourcesBySpotifyID(
                route.params.spotifyID,
                platform.indicator.toLowerCase(),
                platform.apiKeyIndicator,
                years.value,
                1
              ).then(({ data }) => {
                if (data.length > 0) {
                  const yAxis = [];
                  const xAxis = [];
                  platform.series = [];
                  platform.categories = [];
                  platform.dataFound = true;
                  if (props.trendLine) {
                    const trendsArray = [];
                    for (const follower of data) {
                      yAxis.push(follower.value);
                      xAxis.push(moment(follower.timestp).format("MMM YY"));
                      trendsArray.push({
                        x: moment(follower.timestp).unix(),
                        y: Number(follower.value),
                      });
                    }
                    platform.categories = xAxis;
                    platform.series.push({
                      name: capitalizeEveryWordFirstLetter(platform.indicator),
                      type: "line",
                      data: yAxis,
                      color: getPlatformColor(platform.name),
                    });
                    const trend = createTrend(trendsArray, "x", "y");
                    const trendline = [];
                    for (const stream of data) {
                      trendline.push(
                        Math.round(trend.calcY(moment(stream.timestp).unix()))
                      );
                    }
                    platform.series.push({
                      name: "Trend Line",
                      type: "area",
                      data: trendline,
                      color: getPlatformColor(platform.name),
                    });
                  } else {
                    const listeners = [];
                    spotifyListeners.value.map((listener) => {
                      const res = data.find((item) => {
                        return (
                          moment(listener.timestp).format("MMM YY") ===
                          moment(item.timestp).format("MMM YY")
                        );
                      });
                      if (res) {
                        yAxis.push(res.value);
                        xAxis.push(moment(res.timestp).format("MMM YY"));
                        listeners.push(listener.value);
                      }
                    });
                    platform.series.push({
                      name: capitalizeEveryWordFirstLetter(platform.indicator),
                      type: "line",
                      data: yAxis,
                      color: getPlatformColor(platform.name),
                    });
                    platform.series.push({
                      data: listeners,
                      name: "Spotify Listeners",
                      type: "line",
                      color: getPlatformColor("spotify"),
                    });
                    platform.categories = xAxis;
                  }
                } else {
                  platform.dataFound = false;
                }
                data = [];
              })
            );
          });
          await Promise.all(promises);
        } catch (e) {
          console.log(e);
        } finally {
          await filterPlatforms();
          loading.value = false;
        }
      }
    };
    const changeYearValue = async (value) => {
      years.value = value;
      await fetchGraphData();
    };
    return {
      loading,
      years,
      graphPlatforms,
      changeYearValue,
    };
  },
};
</script>
<style lang="scss" scoped></style>
