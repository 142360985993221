<template>
  <div
    class="artist-ui mb-8"
    id="artist-ui-v2"
    v-loading.fullscreen.lock="loading"
  >
    <ArtistDetailSideBarV2
      :artistDetail="artistDetail"
      :chartMetricContacts="chartMetricContacts"
      v-if="!loading"
    />
    <ArtistDetailContentV2 :artistDetail="artistDetail" v-if="!loading" />
  </div>
</template>
<script>
import ArtistDetailSideBarV2 from "@/artists/views/ArtistDetailSideBarV2";
import ArtistDetailContentV2 from "@/artists/views/ArtistDetailContentV2";
import { onMounted, ref } from "vue";
import { getSocialSitesFromChartMetricArray } from "@/utils/staticHelper";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import { fetchArtistDataV2FromMongo } from "@/api/mongoDB";

export default {
  name: "ArtistDetailV2",
  components: {
    ArtistDetailSideBarV2,
    ArtistDetailContentV2,
  },
  setup() {
    const artist = ref({
      uuid: "",
    });
    const route = useRoute();
    const artistDetail = ref({});
    const chartMetricContacts = ref({});
    const loading = ref(false);
    onMounted(async () => {
      try {
        loading.value = true;
        const { data } = await fetchArtistDataV2FromMongo(
          route.params.spotifyID
        );
        artistDetail.value = data;
        chartMetricContacts.value = getSocialSitesFromChartMetricArray(
          data.chartmetricContacts && data.chartmetricContacts.contacts
            ? data.chartmetricContacts.contacts
            : ""
        );
        loading.value = false;
      } catch (e) {
        console.error(e);
      }
    });
    return {
      artist,
      artistDetail,
      loading,
      chartMetricContacts,
      route,
    };
  },
};
</script>
