<template>
  <apexchart
    :height="graphHeight"
    type="radar"
    :options="options"
    :series="series"
  ></apexchart>
</template>
<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "RadarChart",
  props: {
    graphSeries: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    graphHeight: {
      type: String,
      default: "500",
    },
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const fontSize = ref("12px");
    const options = ref({
      chart: {
        type: "radar",
        height: props.graphHeight,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "",
      },
      stroke: {
        width: 2,
      },
      fill: {
        colors: [
          "rgba(255, 128, 170, 0.3)",
          "rgba(204, 233, 255, 0.3)",
          "rgba(230, 204, 255, 0.3)",
          "rgba(128, 255, 212, 0.3)",
        ],
      },
      colors: [
        "rgba(230, 0, 76, 1)",
        "rgba(0, 143, 251, 1)",
        "rgba(140, 26, 255, 1)",
        "rgba(0, 227, 150, 1)",
      ],
      legend: {
        show: true,
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (value, { seriesIndex, dataPointIndex }) => {
            return getRealValues(seriesIndex, dataPointIndex);
          },
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
          formatter: function (value) {
            return value;
          },
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
        },
      },
      xaxis: {
        categories: props.categories,
        hideOverlappingLabels: true,
        labels: {
          show: true,
          showDuplicates: false,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
        },
        crosshairs: {
          show: true,
        },
      },
      // plotOptions: {
      //   radar: {
      //     polygons: {
      //       connectorColors: "#e8e8e8",
      //       fill: { colors: ["#f8f8f8", "#fff"] },
      //     },
      //   },
      // },
    });
    const getRealValues = (seriesIndex, dataPointIndex) => {
      return formatNumberIntoHumanizeForm(
        props.graphSeries[seriesIndex].realValues[dataPointIndex]
      );
    };
    const series = ref(props.graphSeries);
    return { options, series };
  },
};
</script>
