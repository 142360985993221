<template>
  <div class="mt-2 overview-section">
    <el-row :gutter="12" id="artist_bio">
      <el-col :xl="16" :lg="16" class="margin-bottom-12">
        <div
          id="card_description"
          class="shadow-card card card-content"
          :style="[!showMore ? 'height: 170px; overflow: hidden' : '']"
        >
          <div class="card-body pb-1">
            <p class="card-title mb-5">Artist Bio</p>
            <el-skeleton v-if="loading" :rows="3" animated />
            <p
              class="artist-description mb-2"
              v-if="description.length && !loading"
            >
              <span
                v-html="
                  !showMore && description.length > 400
                    ? description.slice(0, 400) + '...'
                    : description + '...'
                "
              ></span>
              <span
                v-if="!showMore && description.length > 400"
                @click="showMore = !showMore"
                class="showMore-text"
              >
                show more</span
              >
              <span
                v-if="showMore"
                @click="showMore = !showMore"
                class="showMore-text"
              >
                show less</span
              >
            </p>
          </div>
        </div>
      </el-col>
      <el-col :xl="8" :lg="8" class="margin-bottom-12">
        <div class="shadow-card card" style="height: 170px; overflow: hidden">
          <div class="card-body pb-1">
            <p class="card-title mb-5">Contact Information</p>
            <el-skeleton v-if="loading" :rows="3" animated />
            <div class="row mb-4" v-else>
              <div class="col-md-6">
                <div class="row">
                  <p class="facts-figures-heading">Booking Agent:</p>
                </div>
                <div class="row">
                  <p class="facts-figures-heading">Record Label:</p>
                </div>
                <div class="row">
                  <p class="facts-figures-heading">Press Contact:</p>
                </div>
                <div class="row">
                  <p class="facts-figures-heading">General Manager:</p>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="row"
                  v-for="(contact_info, index) in contactInformation"
                  :key="index"
                >
                  <p
                    class="facts-figures truncate"
                    data-tag="p"
                    title=""
                    data-tooltip="true"
                    :data-text="contact_info"
                  >
                    {{ contact_info }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- <el-row :gutter="12" id="career_status">
      <el-col :xl="12" :lg="12" :md="24" :sm="24" class="margin-bottom-12">
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Career Stage"
          :fontStyle="true"
          :margin="false"
          :padding="false"
          :shadow-class="true"
        >
          <template v-slot:body>
            <div class="d-flex flex-row">
              <div style="width: 70%">
                <GaugeChart
                  :chart-series="[career.stage_score ? career.stage_score : 0]"
                  :labels="[
                    career.stage ? capitalizeEveryWord(career.stage) : '',
                  ]"
                />
              </div>
              <div class="d-flex flex-column" style="width: 30%">
                <p
                  v-for="(stage, i) in careerStages"
                  :key="i"
                  :class="[
                    stage === (career.stage ? career.stage : '')
                      ? 'theme-color fw-boldest'
                      : ' fw-bolder',
                  ]"
                >
                  {{ capitalizeEveryWord(stage) }}
                </p>
              </div>
            </div>
          </template>
        </CardToolbar>
      </el-col>
      <el-col :xl="12" :lg="12" :md="24" :sm="24" class="margin-bottom-12">
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Career Trend"
          :fontStyle="true"
          :margin="false"
          :padding="false"
          :shadow-class="true"
        >
          <template v-slot:body>
            <div class="d-flex flex-row">
              <div style="width: 70%">
                <GaugeChart
                  :chart-series="[career.trend_score ? career.trend_score : 0]"
                  :labels="[
                    career.trend ? capitalizeEveryWord(career.trend) : '',
                  ]"
                />
              </div>
              <div class="d-flex flex-column" style="width: 30%">
                <p
                  v-for="(stage, i) in careerTrends"
                  :key="i"
                  :class="[
                    stage === (career.trend ? career.trend : '')
                      ? 'theme-color fw-boldest'
                      : ' fw-bolder',
                  ]"
                >
                  {{ capitalizeEveryWord(stage) }}
                </p>
              </div>
            </div>
          </template>
        </CardToolbar>
      </el-col>
    </el-row> -->
    <div class="row" style="--bs-gutter-x: 12px">
      <div
        id="growth_rates"
        class="col-xl-6 col-lg-12 col-md-12 col-sm-12 margin-bottom-12"
      >
        <EmptyCardLoading
          :loading="radarChartLoading"
          :type="'skeleton'"
          :card-height="'600px'"
          v-if="radarChartLoading"
        />
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Growth Rates"
          :fontStyle="true"
          :margin="false"
          :padding="false"
          :shadow-class="true"
          v-if="!radarChartLoading && growthRates.length > 0"
        >
          <template v-slot:body>
            <RadarChart
              :graph-series="growthRates"
              :categories="growthRateCategories"
              :graph-height="'500'"
            />
          </template>
        </CardToolbar>
      </div>
      <div
        id="artist_ratios"
        class="col-xl-6 col-lg-12 col-md-12 col-sm-12 margin-bottom-12"
      >
        <EmptyCardLoading
          :loading="scatterChartLoading"
          :type="'skeleton'"
          :card-height="'600px'"
          v-if="scatterChartLoading"
        />
        <CardToolbar
          :show-toolbar-buttons="false"
          description=""
          title="Spotify Listeners vs Spotify Followers"
          :fontStyle="true"
          :margin="false"
          :padding="false"
          :shadow-class="true"
          v-if="!scatterChartLoading && ratios.length > 0"
        >
          <template v-slot:body>
            <ScatterChartV2
              x-axis-title="Spotify Followers"
              y-axis-title="Spotify Listeners"
              series-name="Followers"
              :graph-data="ratios"
              :apply-ranges="false"
              :ranges="ratioChartRanges"
              :colors="true"
              :hitEvents="true"
            />
          </template>
        </CardToolbar>
      </div>
    </div>
    <div id="artist_scores" class="row margin-bottom-12">
      <BlingRockScore />
    </div>
    <div class="row" id="artist_statistics">
      <div class="shadow-card card mb-5 mb-xl-10">
        <div class="card-body pb-1 mb-5">
          <p class="card-title mb-10">Statistics</p>
          <el-skeleton v-if="loading" :rows="15" animated />
          <div class="row ms-2" v-else>
            <div
              class="col-xl-4 col-lg-6 col-md-6 col-sm-6 platforms-stats-column"
              v-for="(platform, index) in platforms"
              :key="index"
            >
              <div
                class="card-title d-flex align-items-center stats-card-title"
              >
                <div class="el-image icon stats-social-icon pb-1">
                  <img
                    class="el-image__inner"
                    :src="[
                      '/media/svg/social-logos/' +
                        platform.name.toLowerCase() +
                        '.svg',
                    ]"
                    :alt="platform.name.toLowerCase()"
                  />
                </div>
                <div class="card-label fw-bolder m-lg-2">
                  {{ platform.name }}
                </div>
              </div>
              <div
                class="row mt-2"
                v-for="(platformKey, i) in platform.values"
                :key="i"
              >
                <div class="col-md-6">
                  <div class="row">
                    <p class="facts-figures-heading">
                      {{ platformKey.keyName }}:
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <p
                      class="facts-figures stats-facts-figures truncate"
                      data-tag="p"
                      title=""
                      data-tooltip="true"
                      :data-text="platformKey.keyValue"
                    >
                      {{ platformKey.keyValue }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import RadarChart from "@/artists/components/RadarChart.vue";
import BlingRockScore from "@/artists/components/BlingRockScore.vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import ScatterChartV2 from "@/common/components/ScatterChartV2.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import GaugeChart from "@/common/components/GaugeChart.vue";
import { useRoute } from "vue-router";
import {
  capitalizeEveryWordFirstLetter,
  capitalizeFirstLetter,
  checkIsValueAvailableOrNot,
} from "@/utils/staticHelper";
import { getRadarChartValuesOfArtist } from "@/api/genreResult";
import {
  getArtistOverviewDetailV2FromMongo,
  getArtistRatioChartValuesFromMongoV2,
} from "@/api/mongoDB";

export default {
  name: "OverviewV2",
  methods: { capitalizeEveryWordFirstLetter },
  components: {
    RadarChart,
    BlingRockScore,
    ScatterChartV2,
    CardToolbar,
    EmptyCardLoading,
    // GaugeChart,
  },
  props: {
    genres: {
      type: Array,
      default: () => [],
    },
    artistName: {
      type: String,
      required: true,
    },
    career: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const radarChartLoading = ref(false);
    const scatterChartLoading = ref(false);
    const showMore = ref(false);
    const description = ref("");
    const contactInformation = ref([]);
    const route = useRoute();
    const platforms = ref([
      { name: "Spotify", values: [] },
      { name: "Youtube", values: [] },
      { name: "Tiktok", values: [] },
      { name: "Shazam", values: [] },
      { name: "Pandora", values: [] },
      { name: "Deezer", values: [] },
      { name: "Instagram", values: [] },
      { name: "Line", values: [] },
      { name: "Melon", values: [] },
    ]);
    const growthRates = ref([]);
    const growthRateCategories = ref([]);
    const ratios = ref([]);
    const ratioChartRanges = ref({
      yMin: 0,
      yMax: 0,
      xMin: 0,
      xMAx: 0,
    });
    const careerStages = ref([
      "developing",
      "mid-level",
      "mainstream",
      "superstar",
      "legendary",
    ]);
    const careerTrends = ref([
      "decline",
      "gradual decline",
      "steady",
      "growth",
      "explosive growth",
    ]);
    onMounted(async () => {
      try {
        if (route.params.spotifyID) {
          loading.value = true;
          let { data } = await getArtistOverviewDetailV2FromMongo(
            route.params.spotifyID
          );
          if (data.chartmetricMeta) {
            const contactInfo = [];
            contactInfo.push(
              data.chartmetricMeta.booking_agent
                ? data.chartmetricMeta.booking_agent
                : "N/A"
            );
            contactInfo.push(
              data.chartmetricMeta.record_label
                ? data.chartmetricMeta.record_label
                : "N/A"
            );
            contactInfo.push(
              data.chartmetricMeta.press_contact
                ? data.chartmetricMeta.press_contact
                : "N/A"
            );
            contactInfo.push(
              data.chartmetricMeta.general_manager
                ? data.chartmetricMeta.general_manager
                : "N/A"
            );
            contactInformation.value = contactInfo;
            description.value = data.chartmetricMeta.description
              ? data.chartmetricMeta.description
              : "";

            for (const platform of platforms.value) {
              if (platform.name === "Spotify") {
                platform.values.push(
                  {
                    keyName: "Followers",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "sp_followers"
                    ),
                  },
                  {
                    keyName: "Monthly Listeners",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "sp_monthly_listeners"
                    ),
                  },
                  {
                    keyName: "Popularity",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "sp_popularity"
                    ),
                  },
                  {
                    keyName: "Playlist Reach",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "sp_playlist_total_reach"
                    ),
                  }
                );
              } else if (platform.name === "Youtube") {
                platform.values.push(
                  {
                    keyName: "Channel Subscribers",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "ycs_subscribers"
                    ),
                  },
                  {
                    keyName: "Channel View",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "ycs_views"
                    ),
                  },
                  {
                    keyName: "Daily Video Views",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "youtube_daily_video_views"
                    ),
                  },
                  {
                    keyName: "Monthly Video Views",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "youtube_monthly_video_views"
                    ),
                  }
                );
              } else if (platform.name === "Tiktok") {
                platform.values.push(
                  {
                    keyName: "Followers",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "tiktok_followers"
                    ),
                  },
                  {
                    keyName: "Likes",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "tiktok_likes"
                    ),
                  }
                );
              } else if (platform.name === "Pandora") {
                platform.values.push(
                  {
                    keyName: "Streams",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "pandora_lifetime_streams"
                    ),
                  },
                  {
                    keyName: "Artist Stations",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "pandora_lifetime_stations_added"
                    ),
                  }
                );
              } else if (platform.name === "Shazam") {
                platform.values.push({
                  keyName: "Total Counts",
                  keyValue: getKeyValues(
                    data.chartmetricMeta.cm_statistics,
                    "shazam_count"
                  ),
                });
              } else if (platform.name === "Deezer") {
                platform.values.push({
                  keyName: "Fans",
                  keyValue: getKeyValues(
                    data.chartmetricMeta.cm_statistics,
                    "deezer_fans"
                  ),
                });
              } else if (platform.name === "Instagram") {
                platform.values.push({
                  keyName: "Followers",
                  keyValue: getKeyValues(
                    data.chartmetricMeta.cm_statistics,
                    "ins_followers"
                  ),
                });
              } else if (platform.name === "Line") {
                platform.values.push(
                  {
                    keyName: "Artist Likes",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "line_music_artist_likes"
                    ),
                  },
                  {
                    keyName: "Likes",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "line_music_likes"
                    ),
                  },
                  {
                    keyName: "Plays",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "line_music_plays"
                    ),
                  },
                  {
                    keyName: "Music Video Plays",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "line_music_mv_plays"
                    ),
                  }
                );
              } else if (platform.name === "Melon") {
                platform.values.push(
                  {
                    keyName: "Fans",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "melon_artist_fans"
                    ),
                  },
                  {
                    keyName: "Likes",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "melon_likes"
                    ),
                  },
                  {
                    keyName: "Video Likes",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "melon_video_likes"
                    ),
                  },
                  {
                    keyName: "Video Views",
                    keyValue: getKeyValues(
                      data.chartmetricMeta.cm_statistics,
                      "melon_video_views"
                    ),
                  }
                );
              }
            }
          }
          await getRatiosChartValues();
          loading.value = false;
          data = {};
        }
      } catch (e) {
        console.log(e);
      }
    });
    const getKeyValues = (obj, objKey) => {
      return checkIsValueAvailableOrNot("object", obj, objKey, "");
    };

    const capitalizeEveryWord = (text) => {
      return text.replace(
        /(^|\W)([a-z])/g,
        (_, separator, char) => separator + char.toUpperCase()
      );
    };

    const getGrowthRates = async (artistName, spotifyID, genres) => {
      try {
        radarChartLoading.value = true;
        growthRateCategories.value = [];
        if (spotifyID) {
          const { data } = await getRadarChartValuesOfArtist(spotifyID, "");
          growthRates.value.push({
            id: 1,
            name: capitalizeFirstLetter(artistName),
            data: data.yAxis,
            realValues: data.realValues,
          });
          if (growthRateCategories.value.length === 0) {
            growthRateCategories.value = data.xAxis;
          }
        } else if (genres.length > 0) {
          const id = 1;
          for (const genre of genres) {
            const { data } = await getRadarChartValuesOfArtist("", genre.name);
            growthRates.value.push({
              id: id + 1,
              name: capitalizeFirstLetter(genre.name),
              data: data.yAxis,
              realValues: data.realValues,
            });
            if (growthRateCategories.value.length === 0) {
              growthRateCategories.value = data.xAxis;
            }
          }
        }
        if (growthRates.value.length > 1) {
          growthRates.value.sort((a, b) => a.id - b.id);
        }
      } catch (e) {
        console.log(e);
      } finally {
        radarChartLoading.value = false;
      }
    };
    const getRatiosChartValues = async () => {
      try {
        scatterChartLoading.value = true;
        let { data } = await getArtistRatioChartValuesFromMongoV2(
          route.params.spotifyID
        );
        ratios.value = data;
        // ratios.value = data.artistLists;
        // ratioChartRanges.value = data.ranges;
        data = [];
      } catch (e) {
        console.log(e);
      } finally {
        scatterChartLoading.value = false;
      }
    };
    watch(
      () => props.artistName,
      (values) => {
        if (values) {
          getGrowthRates(values, route.params.spotifyID, []);
        }
      },
      { immediate: true }
    );
    watch(
      () => props.genres,
      (values) => {
        if (values.length > 0) {
          getGrowthRates(props.artistName, "", values);
        }
      },
      { immediate: true }
    );
    return {
      loading,
      radarChartLoading,
      scatterChartLoading,
      description,
      contactInformation,
      showMore,
      growthRates,
      growthRateCategories,
      ratios,
      ratioChartRanges,
      platforms,
      careerStages,
      careerTrends,
      getKeyValues,
      capitalizeEveryWord,
    };
  },
};
</script>
<style lang="scss" scoped>
.card {
  .card-body {
    .card-title {
      font-size: 14px;
      font-weight: 500;
      color: #33647f;
    }

    .artist-description {
      font-weight: 400;
      color: #33647f;
    }
  }
}

.facts-figures-heading {
  color: #33647f !important;
  font-size: 12px;
  margin-bottom: 0.5rem !important;
}

.facts-figures {
  color: #33647f !important;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 0.5rem !important;
}

.stats-social-icon {
  width: 30px;
  height: 30px;
}

.stats-card-title {
  border-bottom: 1px solid lightgrey;
}

.stats-facts-figures {
  text-align: end;
}

#artist_statistics {
  --bs-gutter-x: 0rem !important;
}

#artist_scores {
  --bs-gutter-x: 0rem !important;
}

.showMore-text {
  color: #009ef7 !important;
  font-weight: 700;
  cursor: pointer;
}

.platforms-stats-column {
  min-height: 200px;
  max-height: 300px;
}
</style>
