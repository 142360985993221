<template>
  <div class="left-sidebar-v2 me-10" v-loading.fullscreen.lock="loading">
    <div class="card left-sidebar-card-v2 shadow-card" id="artist_card">
      <div class="artist-image align-items-center">
        <img
          class="card-img-top"
          :src="
            artistDetail.imageUrl
              ? artistDetail.imageUrl
              : '/media/avatars/blank.png'
          "
          alt="Card image cap"
        />
      </div>
      <div class="card-body">
        <div class="card-head d-flex flex-column">
          <span
            class="heading-sideBar truncate text-center"
            data-tag="span"
            title=""
            data-tooltip="true"
            :data-text="
              artistDetail && artistDetail.name
                ? artistDetail.name
                : 'Artist Name'
            "
            >{{
              artistDetail && artistDetail.name
                ? artistDetail.name
                : "Artist Name"
            }}</span
          >
          <hr />
        </div>
        <div
          class="card-button mb-8"
          v-if="$route.name !== 'ExternArtistDetailV2'"
        >
          <button
            class="btn btn-sm btn-primary btn-style me-3"
            @click="routeToDiscoverPage"
          >
            Listen
          </button>
          <button
            class="btn btn-sm btn-primary btn-style me-3"
            @click="routeToSandboxPage($route.params.spotifyID)"
          >
            Databox
          </button>
          <ArtistMenuComponent
            :artist="{
              uuid:
                artistDetail && artistDetail.soundchartsUUID
                  ? artistDetail.soundchartsUUID
                  : null,
              name: artistDetail && artistDetail.name ? artistDetail.name : '',
              spotifyID: $route.params.spotifyID,
              chartmetricID:
                artistDetail && artistDetail.chartmetricID
                  ? artistDetail.chartmetricID
                  : null,
            }"
            :is-followed-artist="artistDetail && artistDetail.isFollowed"
            :from-drawer="true"
            :show-follow-button="false"
            :buttons="[
              'forecast',
              'score',
              'followV2',
              '+CRM',
              'download',
              'downloadAll',
              'update',
              'copyDetailPage',
              'copyForecastPage',
              'copyPublicPage',
              'saveArtist',
              'deleteArtist',
              'valuation',
              'songReachProfile',
            ]"
          />
        </div>
        <div class="quick-facts ms-3 mb-8">
          <div class="sub-headings-sidebar mb-2">
            <a href="#">
              <img alt="Logo" src="/favicon.ico" class="h-20px" />
            </a>
            <span> Quick Facts</span>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <div class="row">
                <p class="facts-figures">Monthly Listeners:</p>
              </div>
              <div class="row">
                <p class="facts-figures">48M Growth:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Revenues:</p>
              </div>
              <div class="row">
                <p class="facts-figures">Playlists:</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    checkIsValueAvailableOrNot(
                      'object',
                      artistDetail,
                      'chartmetricSpotifyListenersTotal'
                    )
                  "
                >
                  {{
                    checkIsValueAvailableOrNot(
                      "object",
                      artistDetail,
                      "chartmetricSpotifyListenersTotal"
                    )
                  }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    checkIsValueAvailableOrNot(
                      'object',
                      artistDetail.chartmetricSpotifyListenersGrowth,
                      '48Months',
                      '%'
                    )
                  "
                >
                  {{
                    artistDetail
                      ? checkIsValueAvailableOrNot(
                          "object",
                          artistDetail.chartmetricSpotifyListenersGrowth,
                          "48Months",
                          "%"
                        )
                      : 0
                  }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700 truncate"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    '$' +
                    checkIsValueAvailableOrNot(
                      'object',
                      artistDetail,
                      'chartmetricRevenue'
                    )
                  "
                >
                  $
                  {{
                    checkIsValueAvailableOrNot(
                      "object",
                      artistDetail,
                      "chartmetricRevenue"
                    )
                  }}
                </span>
              </div>
              <div class="row">
                <span
                  class="facts-figures fw-700"
                  data-tag="span"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    checkIsValueAvailableOrNot(
                      'object',
                      artistDetail.chartmetricMeta &&
                        artistDetail.chartmetricMeta.cm_statistics
                        ? artistDetail.chartmetricMeta.cm_statistics
                        : {},
                      'num_sp_playlists'
                    )
                  "
                >
                  {{
                    checkIsValueAvailableOrNot(
                      "object",
                      artistDetail.chartmetricMeta &&
                        artistDetail.chartmetricMeta.cm_statistics
                        ? artistDetail.chartmetricMeta.cm_statistics
                        : {},
                      "num_sp_playlists"
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="genre-v2 ms-3">
          <p class="sub-headings-sidebar">Main Genres</p>
          <span
            v-for="(genre, index) in artistDetail.chartmetricMeta &&
            artistDetail.chartmetricMeta &&
            artistDetail.chartmetricMeta.tags
              ? artistDetail.chartmetricMeta.tags
              : []"
            :key="index"
            class="badge badge-secondary m-1 active"
            @click="routeToGenrePage(genre, 'main')"
          >
            {{ genre.name }}
          </span>
        </div>
        <hr />
        <div class="genre-v2 ms-3">
          <p class="sub-headings-sidebar">Sub Genres</p>
          <span
            v-for="(genre, index) in artistDetail.spotifyGenres"
            :key="index"
            :class="[
              'badge badge-secondary m-1',
              genre.analyzed ? 'active' : '',
              genre.addedToQueue ? 'in-queue' : '',
            ]"
            @click="routeToGenrePage(genre, 'sub')"
          >
            {{ genre.name }}
          </span>
        </div>
        <hr />
        <div class="links ms-3">
          <p class="sub-headings-sidebar">Links</p>
          <a
            href="javascript:void(0)"
            v-if="
              (artistDetail.chartmetricContacts &&
                artistDetail.chartmetricContacts.emails &&
                artistDetail.chartmetricContacts.emails.length > 0) ||
              (artistDetail.googleScrapedContacts &&
                artistDetail.googleScrapedContacts.emails &&
                artistDetail.googleScrapedContacts.emails.length > 0)
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="icon-style email-icon"
            >
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.021 17.824c-3.907 0-6.021-2.438-6.021-5.586 0-3.363 2.381-6.062 6.638-6.062 3.107 0 5.362 2.019 5.362 4.801 0 4.356-5.165 5.506-4.906 3.021-.354.555-.927 1.177-2.026 1.177-1.257 0-2.04-.92-2.04-2.403 0-2.222 1.461-4.1 3.19-4.1.829 0 1.399.438 1.638 1.11l.232-.816h1.169c-.122.416-1.161 4.264-1.161 4.264-.323 1.333.675 1.356 1.562.648 1.665-1.29 1.75-4.664-.499-6.071-2.411-1.445-7.897-.551-7.897 4.347 0 2.806 1.976 4.691 4.914 4.691 1.719 0 2.771-.465 3.648-.974l.588.849c-.856.482-2.231 1.104-4.391 1.104zm-1.172-7.153c-.357.67-.588 1.538-.588 2.212 0 1.805 1.761 1.816 2.626.12.356-.697.586-1.586.586-2.265 0-1.458-1.748-1.717-2.624-.067z"
              />
            </svg>
          </a>
          <a
            :href="
              chartMetricContacts.facebook
                ? chartMetricContacts.facebook
                : artistDetail.googleScrapedContacts.facebook
            "
            v-if="
              chartMetricContacts.facebook ||
              (artistDetail.googleScrapedContacts &&
                artistDetail.googleScrapedContacts.facebook)
            "
            target="_blank"
          >
            <svg
              class="icon-style facebook-icon"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              clip-rule="evenodd"
            >
              <path
                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0 4.5c-3.314 0-6 2.487-6 5.556 0 1.748.872 3.307 2.236 4.326v2.118l2.043-1.121c.545.151 1.123.232 1.721.232 3.314 0 6-2.487 6-5.555 0-3.069-2.686-5.556-6-5.556zm.596 7.481l-1.528-1.629-2.981 1.629 3.279-3.481 1.566 1.63 2.944-1.63-3.28 3.481z"
              />
            </svg>
          </a>
          <a
            :href="
              chartMetricContacts.instagram
                ? chartMetricContacts.instagram
                : artistDetail.googleScrapedContacts.instagram
            "
            v-if="
              chartMetricContacts.instagram ||
              (artistDetail.googleScrapedContacts &&
                artistDetail.googleScrapedContacts.instagram)
            "
            target="_blank"
          >
            <svg
              class="icon-style instagram-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"
              />
            </svg>
          </a>
          <a
            :href="
              chartMetricContacts.soundcloud
                ? chartMetricContacts.soundcloud
                : artistDetail.googleScrapedContacts.soundcloud
            "
            v-if="
              chartMetricContacts.soundcloud ||
              (artistDetail.googleScrapedContacts &&
                artistDetail.googleScrapedContacts.soundcloud)
            "
            target="_blank"
          >
            <svg
              class="icon-style soundcloud-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.709 15h-.541v-4.4c.167-.126.347-.234.541-.309v4.709zm1.625 0h.543v-5.031c-.125.145-.24.299-.337.468l-.206-.101v4.664zm-1.082 0h.541v-4.826c-.274-.043-.337-.027-.541-.005v4.831zm-2.168 0h.543v-3.829c-.17.25-.302.529-.375.836l-.168-.099v3.092zm-1.625-2.863c-.329.298-.541.738-.541 1.236 0 .499.212.938.541 1.238v-2.474zm10.227-.366c-.11-1.548-1.302-2.771-2.772-2.771-.554 0-1.064.179-1.496.479v5.521h4.924c.87 0 1.576-.76 1.576-1.695 0-1.218-1.174-2.056-2.232-1.534zm-9.143-.016c-.163-.014-.285-.017-.541.066v3.103c.241.078.345.076.541.076v-3.245z"
              />
            </svg>
          </a>
          <a
            :href="
              chartMetricContacts.twitter
                ? chartMetricContacts.twitter
                : artistDetail.googleScrapedContacts.twitter
            "
            v-if="
              chartMetricContacts.twitter ||
              (artistDetail.googleScrapedContacts &&
                artistDetail.googleScrapedContacts.twitter)
            "
            target="_blank"
          >
            <svg
              class="icon-style twitter-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"
              />
            </svg>
          </a>
          <a
            :href="
              chartMetricContacts.youtube
                ? chartMetricContacts.youtube
                : artistDetail.googleScrapedContacts.youtube
            "
            v-if="
              chartMetricContacts.youtube ||
              (artistDetail.googleScrapedContacts &&
                artistDetail.googleScrapedContacts.youtube)
            "
            target="_blank"
          >
            <svg
              class="icon-style youtube-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArtistMenuComponent from "@/artists/components/ArtistMenuComponent";
import { ref } from "vue";
import { addArtistsToCRM } from "@/api/hubspot";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useSpotifyApi from "@/common/composables/useSpotifyApi";
import { saveDataIntoToBeScrappedDocument } from "@/api/mongoDB";
import {
  formatNumberIntoHumanizeForm,
  checkIsValueAvailableOrNot,
} from "@/utils/staticHelper";

export default {
  name: "ArtistDetailSideBarV2",
  components: {
    ArtistMenuComponent,
  },
  props: {
    artistDetail: {
      type: Object,
    },
    chartMetricContacts: {
      type: Object,
    },
  },
  setup(props) {
    const artist = ref({
      uuid: "",
    });
    const searchResults = ref({
      artists: [],
    });
    const route = useRoute();
    const loading = ref(false);
    const store = useStore();
    const addToCrm = async (spotifyID) => {
      loading.value = true;
      if (spotifyID) {
        try {
          await addArtistsToCRM([{ spotifyID: spotifyID }]);
        } catch (e) {
          ElNotification({
            title: "Error",
            message: "Error adding to CRM, please try later!",
            type: "error",
          });
        } finally {
          loading.value = false;
          ElNotification({
            title: "Success",
            message: "Artist added to CRM!",
            type: "success",
          });
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Error adding to CRM, please try later!",
          type: "error",
        });
      }
      loading.value = false;
    };
    const routeToDiscoverPage = () => {
      store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      window.open(`/discover?search=${props.artistDetail.name}`, "_blank");
    };
    const routeToGenrePage = async (genre, type) => {
      if (route.name !== "ExternArtistDetailV2") {
        if ((genre.analyzed && !genre.addedToQueue) || type === "main") {
          window.open(`/genre-v2?type=${type}&genre=${genre.name}`, "_blank");
        } else if (!genre.addedToQueue && !genre.analyzed) {
          loading.value = true;
          let { total } = await fetchArtistsByFilters(0, {
            genre: genre.name,
          });
          if (total) {
            const promises = [];
            let loopCounter;
            if (total >= 1000) {
              total = 1000;
              loopCounter = 20;
            } else {
              loopCounter = Math.ceil(total / 50);
            }

            for (let i = 0; i < loopCounter; i++) {
              promises.push(
                new Promise((resolve) => {
                  fetchArtistsByFilters(i, {
                    genre: genre.name,
                  })
                    .then(({ artists }) => {
                      searchResults.value.artists.push(...artists);
                      resolve();
                    })
                    .catch(() => {
                      resolve();
                    });
                })
              );
            }
            await Promise.all(promises);
            const artistListOfSpotifyIDAndNameOfSameGenres =
              searchResults.value.artists.map((artist) => {
                return {
                  spotifyId: artist.id,
                  name: artist.name,
                };
              });
            saveDataIntoToBeScrappedDocument({
              artists: artistListOfSpotifyIDAndNameOfSameGenres,
              genre: genre.name,
            });
            genre.addedToQueue = true;
            ElNotification({
              title: "Success",
              message: "Genre successfully added to queue",
              type: "success",
            });
          } else {
            ElNotification({
              title: "Error",
              message: "Error getting data from Spotify",
              type: "error",
            });
          }
          loading.value = false;
        }
      }
    };
    const fetchArtistsByFilters = async (offset, filters) => {
      const parsedArtists = [];
      const next = null;
      if (offset < 20) {
        const spotifyApi = await useSpotifyApi(store);
        try {
          const {
            body: { artists },
          } = await spotifyApi.search(`genre:"${filters.genre}"`, ["artist"], {
            limit: 50,
            offset: 50 * offset,
          });
          if (artists.items && artists.items.length > 0) {
            if (
              filters.followersRange &&
              filters.followersRange.length > 0 &&
              filters.followersRange[0] &&
              filters.followersRange[1]
            ) {
              artists.items = artists.items.filter((artist) => {
                return (
                  artist.followers.total >= filters.followersRange[0] &&
                  artist.followers.total <= filters.followersRange[1]
                );
              });
            }
            for (const item of artists.items) {
              parsedArtists.push({
                id: item.id,
                name: item.name,
                image: item.images[0]
                  ? item.images[0].url
                  : "/media/avatars/blank.png",
                followers: item.followers.total,
                popularity: item.popularity,
              });
            }
            return {
              next: artists.next,
              artists: parsedArtists,
              total: artists.total,
            };
          }
        } catch (e) {
          console.log(e);
        }
      }
      return { next, parsedArtists };
    };
    const routeToSandboxPage = (spotifyID) => {
      console.log("functioned called", spotifyID);
      if (spotifyID) {
        window.open(
          `${process.env.VUE_APP_SANDBOX_URI}/?spotify_id=${spotifyID}`,
          "_blank"
        );
      }
    };
    return {
      artist,
      loading,
      route,
      addToCrm,
      routeToDiscoverPage,
      routeToGenrePage,
      formatNumberIntoHumanizeForm,
      checkIsValueAvailableOrNot,
      routeToSandboxPage,
    };
  },
};
</script>
<style scoped lang="scss">
.left-sidebar-card-v2 {
  .artist-image {
    width: 225px;
    height: 225px;
    margin: 0 auto;
    padding: 0;

    .card-img-top {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.artist-card-top {
  top: 0 !important;
  bottom: 7% !important;
}

.icon-style {
  margin-right: 4px;
  fill: #9bacca;
}

.icon-style:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

@media only screen and (max-width: 1024px) {
  .left-sidebar-card-v2 {
    //.artist-image {
    //  width: 205px !important;
    //  height: 205px !important;
    //}

    .card-body {
      .card-button {
        .btn-style {
          padding: 8px 20px 8px 20px !important;
        }
      }

      .genre-v2 {
        .badge-secondary {
          padding: 6px !important;
        }
      }
    }
  }
}
</style>
