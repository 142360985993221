<template>
  <div class="artist-main-content" @scroll="handleScroll()">
    <div class="shadow-card artist-sub-navbar card">
      <div class="row">
        <div class="col-12">
          <div id="artist_menu_list" class="top-menu-list me-2" role="tablist">
            <ul
              class="list-inline mt-4"
              style="white-space: nowrap; overflow: scroll"
            >
              <li
                class="list-inline-item"
                @click="scrollToElementWithMargin('overview_v2', 125)"
              >
                <a
                  id="overview_tab"
                  class="active"
                  data-section-main="#overview_v2"
                >
                  <img alt="br-blue icon" src="/media/svg/BR-Blue.svg" />
                  <span class="social-icon text-xs-center"> Overview </span>
                </a>
              </li>
              <li
                class="list-inline-item"
                @click="scrollToElementWithMargin('performance', 125)"
              >
                <a id="performance_tab" data-section-main="#performance"
                  ><img alt="br-red icon" src="/media/svg/br-red.svg" />
                  <span class="social-icon text-xs-center"> Performance </span>
                </a>
              </li>
              <li
                class="list-inline-item"
                @click="scrollToElementWithMargin('social_media', 125)"
              >
                <a data-section-main="#social_media"
                  ><img alt="social icon" src="/media/svg/social.svg" />
                  <span class="social-icon text-xs-center">
                    Social Media
                  </span></a
                >
              </li>
              <li
                class="list-inline-item"
                @click="scrollToElementWithMargin('top_10_songs', 125)"
              >
                <a data-section-main="#top_10_songs">
                  <img alt="top10 icon" src="/media/svg/top10.svg" />
                  <span class="social-icon text-xs-center">
                    Top Tracks
                  </span></a
                >
              </li>
              <!--              <li-->
              <!--                class="list-inline-item ms-2"-->
              <!--                @click="scrollToElementWithMargin('revenues_v2', 125)"-->
              <!--              >-->
              <!--                <a data-section-main="#revenues_v2">-->
              <!--                  <img src="/media/svg/revs.svg" alt="revs icon" />-->
              <!--                  <span class="social-icon text-xs-center"> Revenues </span>-->
              <!--                </a>-->
              <!--              </li>-->
              <li
                class="list-inline-item"
                @click="scrollToElementWithMargin('spotify_tab', 125)"
              >
                <a data-section-main="#spotify_tab">
                  <img
                    alt="spotify icon"
                    src="/media/svg/iconmonstr-spotify-1.svg"
                  />
                  <span class="social-icon text-xs-center"> Spotify </span>
                </a>
              </li>
              <li
                class="list-inline-item"
                @click="scrollToElementWithMargin('playlists_main_tab', 125)"
              >
                <a data-section-main="#playlists_main_tab">
                  <img alt="playlist icon" src="/media/svg/playlist.svg" />
                  <span class="social-icon text-xs-center"> Playlists </span>
                </a>
              </li>
              <li class="list-inline-item" @click="changeTab('other')">
                <img alt="airplay icon" src="/media/svg/airplay.svg" />
                <span class="social-icon text-xs-center"> Other </span>
              </li>
            </ul>
            <hr />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div
          class="d-flex justify-content-start"
          style="white-space: nowrap; overflow: scroll"
        >
          <div id="artist_sub_sub_menu_list" class="top-menu-list">
            <ul class="list-inline">
              <li
                v-for="(list, index) in subListArray"
                :key="index"
                class="list-inline-item ms-4 sub-list-inline"
              >
                <span
                  :class="[
                    'list-text text-xs-center',
                    activeSubList === list.id ? 'active-sub-text' : '',
                  ]"
                  :data-section="'#' + list.id"
                  @click="scrollToElementWithMargin(list.id, 130)"
                >
                  {{ list.tabName }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="d-flex justify-content-end top-menu-search">
          <div class="search-bar">
            <input
              class="form-control form-control-solid me-3 search-field"
              placeholder="Search other artists"
            />
            <svg
              class="search-icon"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div id="artist_detail_v2" class="row artist-detail-v2 mt-8">
      <div id="overview_v2">
        <div class="artist-content-heading">Overview</div>
        <OverviewV2
          :artistName="
            artistDetail && artistDetail.name ? artistDetail.name : ''
          "
          :genres="
            artistDetail &&
            artistDetail.chartmetricMeta &&
            artistDetail.chartmetricMeta.tags
              ? artistDetail.chartmetricMeta.tags
              : []
          "
          :career="
            artistDetail &&
            artistDetail.chartmetricMeta &&
            artistDetail.chartmetricMeta.career_status
              ? artistDetail.chartmetricMeta.career_status
              : {}
          "
        />
      </div>
      <div id="performance">
        <div class="artist-content-heading">Performance</div>
        <ArtistPerformanceV2
          :chartmetric-spotify-listeners-total="
            artistDetail && artistDetail.chartmetricSpotifyListenersTotal
              ? artistDetail.chartmetricSpotifyListenersTotal
              : 0
          "
          :chartmetric-spotify-followers-growth="
            artistDetail && artistDetail.chartmetricSpotifyFollowersGrowth
              ? artistDetail.chartmetricSpotifyFollowersGrowth
              : {}
          "
          :chartmetric-spotify-listeners-growth="
            artistDetail && artistDetail.chartmetricSpotifyListenersGrowth
              ? artistDetail.chartmetricSpotifyListenersGrowth
              : {}
          "
          :spotify-listeners-trend-growth="
            artistDetail.spotifyListenersTrendGrowth
              ? artistDetail.spotifyListenersTrendGrowth
              : {}
          "
        />
      </div>
      <div id="social_media">
        <div class="artist-content-heading">Social Media</div>
        <SocialMediaV2 />
      </div>
      <div id="top_10_songs">
        <div class="artist-content-heading">Top Tracks</div>
        <ArtistTop10Songs :artistDetail="artistDetail" />
      </div>
      <div id="spotify_tab">
        <div class="artist-content-heading">Spotify</div>
        <SpotifyV2
          :listeners-by-country="
            artistDetail.listenersByCountry
              ? artistDetail.listenersByCountry
              : []
          "
        />
      </div>
      <div id="playlists_main_tab">
        <div class="artist-content-heading">Playlists</div>
        <ArtistPlaylistsV2
          :cm-statistics="
            artistDetail &&
            artistDetail.chartmetricMeta &&
            artistDetail.chartmetricMeta.cm_statistics
              ? artistDetail.chartmetricMeta.cm_statistics
              : {}
          "
          :playlists-subscriber-growth="
            artistDetail && artistDetail.playlistsSubscriberGrowth
              ? artistDetail.playlistsSubscriberGrowth
              : {}
          "
          :playlists-subscriber-growth-editorial="
            artistDetail && artistDetail.playlistsSubscriberGrowthEditorial
              ? artistDetail.playlistsSubscriberGrowthEditorial
              : {}
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import OverviewV2 from "@/artists/components/OverviewV2.vue";
import ArtistPerformanceV2 from "@/artists/components/ArtistPerformanceV2.vue";
import ArtistPlaylistsV2 from "@/artists/components/ArtistPlaylistsV2.vue";
import SocialMediaV2 from "@/artists/components/SocialMediaV2.vue";
import ArtistTop10Songs from "@/artists/components/ArtistTop10Songs.vue";
// import ArtistRevenuesV2 from "@/artists/views/ArtistRevenuesV2";
import SpotifyV2 from "@/artists/components/SpotifyV2.vue";
import { onBeforeMount, ref, onMounted } from "vue";
import { scrollToElementWithMargin } from "@/utils/staticHelper";
import $ from "jquery";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "ArtistDetailContentV2",
  components: {
    OverviewV2,
    ArtistPerformanceV2,
    ArtistPlaylistsV2,
    SpotifyV2,
    SocialMediaV2,
    ArtistTop10Songs,
    // ArtistRevenuesV2,
  },
  props: {
    artistDetail: {
      type: Object,
    },
  },
  setup() {
    const activeTab = ref("overview_v2");
    const activeSubList = ref("artist_bio");
    const subListArray = ref([
      { tabName: "Artist Bio", id: "artist_bio" },
      { tabName: "Career Status", id: "career_status" },
      { tabName: "Growth Rates", id: "growth_rates" },
      { tabName: "Listeners vs Followers", id: "artist_ratios" },
      { tabName: "Score", id: "artist_scores" },
      { tabName: "Statistics", id: "artist_statistics" },
    ]);
    const store = useStore();
    const route = useRoute();

    onMounted(async () => {
      if (route.params.spotifyID) {
        await store.dispatch("ArtistModule/getArtistListeners", {
          spotifyID: route.params.spotifyID,
          years: 3,
        });
      }
    });

    onBeforeMount(() => {
      window.addEventListener("scroll", handleScroll);
    });
    const handleScroll = () => {
      const scrollPos = $(document).scrollTop() + 250;
      $("#artist_menu_list a").each(function () {
        const currLink = $(this);
        const refElement = $(currLink.data("section-main"));
        if (
          refElement.position().top <= scrollPos &&
          refElement.position().top + refElement.height() > scrollPos
        ) {
          $("#artist_menu_list li a").removeClass("active");
          changeTab(currLink.data("section-main"));
          currLink.addClass("active");
        } else {
          currLink.removeClass("active");
        }
      });
      if (scrollPos === 250 || scrollPos < 250) {
        $("#overview_tab").addClass("active");
        changeTab("#overview_v2");
        activeSubList.value = "artist_bio";
      }
      $("#artist_sub_sub_menu_list li span").each(function () {
        const currList = $(this);
        const refList = $(currList.attr("data-section"));

        if (
          refList.position().top <= scrollPos &&
          refList.position().top + refList.height() > scrollPos
        ) {
          activeSubList.value = currList.attr("data-section").replace("#", "");
        }
      });
    };

    const changeTab = (tabName) => {
      activeTab.value = tabName;
      if (tabName === "#overview_v2") {
        subListArray.value = [
          { tabName: "Artist Bio", id: "artist_bio" },
          { tabName: "Career Status", id: "career_status" },
          { tabName: "Growth Rates", id: "growth_rates" },
          { tabName: "Listeners vs Followers", id: "artist_ratios" },
          { tabName: "Score", id: "artist_scores" },
          { tabName: "Statistics", id: "artist_statistics" },
        ];
      } else if (tabName === "#performance") {
        subListArray.value = [
          { tabName: "Monthly Listeners", id: "monthly_listeners" },
          { tabName: "Listeners Trend", id: "listener_trend" },
          { tabName: "Popularity", id: "popularity" },
          { tabName: "Followers", id: "monthly_follower" },
        ];
      } else if (tabName === "#playlists_main_tab") {
        subListArray.value = [
          { tabName: "Playlists", id: "playlists" },
          { tabName: "Total Playlists", id: "total_playlists" },
          { tabName: "New Playlists", id: "new_playlists" },
          { tabName: "Total Playlist Subscribers", id: "playlist_subscribers" },
          {
            tabName: "New Playlist Subscribers",
            id: "new_playlist_subscribers",
          },
        ];
      } else if (tabName === "#spotify_tab") {
        subListArray.value = [
          {
            tabName: "Monthly Listeners by Country",
            id: "spotify_monthly_listeners_by_country",
          },
        ];
      } else if (tabName === "#social_media") {
        subListArray.value = [
          { tabName: "Fanbase Growth Overtime", id: "growth_overtime" },
          {
            tabName: "Fanbase Engagement Overtime",
            id: "fanbase_engagement_overtime",
          },
          { tabName: "Total Fanbase Distribution", id: "fanbase_distribution" },
          { tabName: "Fanbase Growth", id: "fanbase_growth" },
          // { tabName: "Weekly Post Frequency", id: "weekly_post_frequency" },
        ];
      } else if (tabName === "#top_10_songs") {
        subListArray.value = [
          {
            tabName: "Top Tracks",
            id: "top_10_tracks_on_spotify",
          },
          {
            tabName: "Catalogue Age Metrics",
            id: "catalogue_age_metrics",
          },
          {
            tabName: "Spotify Catalog Age Graph",
            id: "spotify_catalog_age_graph",
          },
          {
            tabName: "Catalogue Age vs Streams Last Month",
            id: "age_vs_streams_last_month",
          },
          {
            tabName: "Total Streams vs Streams Last Month",
            id: "total_vs_streams_last_month",
          },
        ];
      }
      // else if (tabName === "#revenues_v2") {
      //   subListArray.value = [
      //     {
      //       tabName: "Revenue past 12 months",
      //       id: "revenue_past_12_months",
      //     },
      //     {
      //       tabName: "Artist Forecast",
      //       id: "artist_forecast",
      //     },
      //   ];
      // }
      else {
        subListArray.value = [];
      }
    };
    return {
      subListArray,
      activeTab,
      activeSubList,
      changeTab,
      scrollToElementWithMargin,
      handleScroll,
    };
  },
};
</script>
<style lang="scss" scoped>
.artist-detail-card-top {
  top: 0 !important;
  bottom: 1.5% !important;
}

.artist-detail-v2 {
  --bs-gutter-x: 0 !important;
}

input::placeholder {
  color: #33647f !important;
  font-size: 12px;
  font-weight: 400;
}

.top-menu-search {
  max-width: 300px;

  .search-bar {
    position: relative;
    width: -webkit-fill-available;
    margin-right: 1rem;
    margin-top: -5px;
  }

  .search-field {
    padding: 0.5rem 1.5rem !important;
    line-height: 0 !important;
  }

  .search-icon {
    position: absolute;
    right: 2%;
    font-size: 24px;
    bottom: 30%;
    fill: #33647f;
  }
}

.artist-main-content {
  .card {
    .list-inline {
      .list-inline-item {
        margin-left: 0;
        margin-right: 4px;

        .list-text {
          font-size: 12px;
          color: #33647f;
          font-weight: 400;
        }
      }
    }
  }
}

//
//@media only screen and (max-width: 1024px) {
//  .search-icon {
//    bottom: 55%;
//  }
//}

//@media only screen and (max-width: 1024px) {
//  .artist-detail-card {
//    .card {
//      .list-inline {
//        .list-inline-item {
//          margin-top: 10px;
//          margin-left: 0.25rem;
//        }
//      }
//    }
//  }
//}

.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.sub-list-inline {
  padding: 2px !important;
}

.artist-sub-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  transition: 0.8s;
}

.active-sub-text {
  color: black !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 1200px) {
  .artist-main-content {
    .card {
      .list-inline {
        .list-inline-item {
          margin-left: 0;
          margin-right: 2px;

          .social-icon {
            font-size: 10px;
          }

          .list-text {
            font-size: 10px;
          }
        }
      }
    }
  }
  .top-menu-search {
    max-width: 200px;

    .search-icon {
      bottom: 35%;
    }
  }
}
</style>
