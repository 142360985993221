<template>
  <div class="fanbase-line-chart">
    <div class="card-title d-flex align-items-center">
      <el-image
        class="icon"
        :src="'/media/svg/social-logos/' + platform.name + '.svg'"
      />
      <div class="card-label fw-bolder m-lg-4">
        {{ platform.name.toUpperCase() }}
      </div>
    </div>
    <ScoreChartV2
      :colors="[platform.color]"
      :stroke="stroke"
      :chart-series="platform.series"
      :categories="platform.categories"
      :show-label="false"
      :graph-type="'area'"
      :fill="fill"
      :tickAmount="12"
      :graph-height="250"
      :y-axis-opposite="yAxisOpposite"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import ScoreChartV2 from "@/artists/components/ScoreChartV2";

export default {
  name: "FanbaseLineChartV2",
  components: { ScoreChartV2 },
  props: {
    platform: {
      type: Object,
      required: true,
    },
    yAxisOpposite: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const stroke = ref({
      width: [3, 2],
      dashArray: [0, 6],
      curve: "smooth",
    });
    const fill = ref({
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.55,
      },
    });

    return { stroke, fill };
  },
};
</script>
<style lang="scss" scoped>
.fanbase-line-chart {
  .icon {
    height: 40px;
    width: 40px;
  }
}
</style>
