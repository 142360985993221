<template>
  <apexchart
    type="scatter"
    :series="series"
    :options="options"
    height="500"
    @zoomed="options.chart.events.zoomed"
    @mounted="options.chart.events.mounted"
  />
</template>
<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";
import { useRoute } from "vue-router";
import $ from "jquery";

export default {
  name: "ScatterChartV2",
  props: {
    xAxisTitle: {
      type: String,
      default: null,
    },
    yAxisTitle: {
      type: String,
      default: null,
    },
    seriesName: {
      type: String,
      default: null,
    },
    graphData: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Boolean,
      default: false,
    },
    hitEvents: {
      type: Boolean,
      default: false,
    },
    applyRanges: {
      type: Boolean,
      default: false,
    },
    ranges: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const fontSize = ref("12px");
    const route = useRoute();
    const options = ref({
      chart: {
        events: {
          zoomed: () => {
            addClasses();
          },
          mounted: () => {
            addClasses();
            clickResetButton();
          },
        },
        height: 350,
        type: "scatter",
        zoom: {
          type: "xy",
        },
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      colors: [
        function ({ seriesIndex, dataPointIndex, w }) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          if (data.spotifyID === route.params.spotifyID && props.colors) {
            return "rgba(230, 0, 76, 1)";
          } else {
            return "rgba(0, 143, 251, 0.85)";
          }
        },
      ],
      xaxis: {
        type: "numeric",
        // min: 0,
        // max: Math.pow(
        //   10,
        //   Math.ceil(Math.log10(Math.max(...props.graphData.map((o) => o.x))))
        // ),
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "rgb(111, 115, 144)",
        },
        forceNiceScale: true,
        title: {
          text: props.xAxisTitle,
          style: {
            color: labelColor,
            fontSize: fontSize,
            fontWeight: 400,
          },
        },
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,

          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(Math.round(value));
          },
        },
      },
      yaxis: {
        // min: (min) => {
        //   min = getRanges("y", "min");
        //   return min;
        // },
        // max: (max) => {
        //   max = getRanges("y", "max");
        //   return max;
        // },
        forceNiceScale: true,
        title: {
          text: props.yAxisTitle,
          style: {
            color: labelColor,
            fontSize: fontSize,
            fontWeight: 400,
          },
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
      tooltip: {
        style: {
          fontSize: fontSize.value,
        },
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const img = data.imageUrl;
          return (
            "<div style='min-width: 250px; max-width: 350px;'>" +
            "<ul class='m-6 ms-0 tooltip-list'>" +
            "<div class='d-flex align-items-center mb-4'><img src='" +
            img +
            "' alt='Avatar' class='avatar-tooltip'/> <span class='ms-2 truncate'><b>" +
            data.name +
            "</b> </span></div>" +
            "<li>" +
            "<div class='row'>" +
            "<div class='col-6'>Listeners :</div>" +
            "<div class='col-6'><b class='truncate'>" +
            formatNumberIntoHumanizeForm(data.y) +
            "</b></div>" +
            "</div>" +
            "</li>" +
            "<li>" +
            "<div class='row'>" +
            "<div class='col-6'>" +
            props.seriesName +
            " :</div>" +
            "<div class='col-6'><b class='truncate'>" +
            formatNumberIntoHumanizeForm(data.x) +
            "</b></div>" +
            "</div>" +
            "</li>" +
            "</ul>" +
            "</div>"
          );
        },
      },
    });
    const series = ref([
      {
        name: "Listeners",
        data: props.graphData,
      },
    ]);
    const getRanges = (axis, rangeType) => {
      if (axis === "x") {
        if (props.applyRanges && rangeType === "max") {
          return Math.pow(10, Math.ceil(Math.log10(props.ranges.xMax)));
        } else if (!props.applyRanges && rangeType === "max") {
          return Math.pow(
            10,
            Math.ceil(Math.log10(Math.max(...props.graphData.map((o) => o.x))))
          );
        } else if (props.applyRanges && rangeType === "min") {
          return Math.pow(10, Math.ceil(Math.log10(props.ranges.xMin)));
        } else if (!props.applyRanges && rangeType === "min") {
          return 0;
        }
      } else if (axis === "y") {
        if (props.applyRanges && rangeType === "max") {
          return Math.pow(10, Math.ceil(Math.log10(props.ranges.yMax)));
        } else if (!props.applyRanges && rangeType === "max") {
          return Math.pow(
            10,
            Math.ceil(Math.log10(Math.max(...props.graphData.map((o) => o.x))))
          );
        } else if (props.applyRanges && rangeType === "min") {
          return Math.pow(10, Math.ceil(Math.log10(props.ranges.yMin)));
        } else if (!props.applyRanges && rangeType === "min") {
          return 0;
        }
      }
    };
    const addClasses = () => {
      if (props.hitEvents) {
        $(document).ready(function () {
          $(".apexcharts-series-scatter circle").each(function () {
            const refElement = $(this).attr("fill");
            if (refElement === "rgba(230, 0, 76, 1)") {
              const id = $(this).attr("id");
              $("#" + id).addClass("updateArtistRadius");
            }
          });
        });
      }
    };
    const clickResetButton = () => {
      $(document).ready(function () {
        $(".apexcharts-reset-icon").click();
      });
    };
    return { options, series, getRanges };
  },
};
</script>
<style>
.avatar-tooltip {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.tooltip-list {
  list-style-type: none;
}

.updateArtistRadius {
  r: 10 !important;
}
</style>
