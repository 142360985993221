<template>
  <div class="h-100">
    <apexchart
      type="bar"
      ref="chartRef"
      :series="series"
      :options="options"
      height="100%"
    />
  </div>
</template>
<script>
import { ref, watch } from "vue";

import { getCSSVariableValue } from "../../assets/ts/_utils";
import { platforms } from "@/assets/static/platforms";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const fontSize = ref("12px");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const chartRef = ref(null);

    const options = {
      chart: {
        height: "100%",
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: props.data.countries,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        title: {
          text: props.title,
          style: {
            color: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
        labels: {
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
        },
      },
      colors: [platforms.spotify.color, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = [
      {
        name: "Listeners",
        data: props.data.followers,
      },
    ];

    watch(
      () => props.data,
      (updatedValue) => {
        options.xaxis.categories = updatedValue.countries;
        series[0].data = updatedValue.followers;
        if (chartRef.value) {
          chartRef.value.updateOptions(options);
        }
      }
    );

    return {
      chartRef,
      options,
      series,
    };
  },
};
</script>
<style lang="scss" scoped></style>
