<template>
  <div class="margin-bottom-12">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'500px'"
      :rows="10"
    />
    <CardToolbar
      description="The size of Artist's fanbase and distribution across social media and music channels"
      :show-toolbar-buttons="false"
      title="Total Fanbase Distribution"
      :font-style="true"
      :shadow-class="true"
      :margin="false"
      v-if="platformFollowers.length > 0 && series.length > 0 && !loading"
    >
      <template v-slot:body>
        <el-row>
          <el-col :xs="24" :md="24" :lg="12">
            <DonutChart :series="series" :labels="labels" :colours="colours" />
          </el-col>
          <el-col :xs="24" :md="24" :lg="12">
            <el-row :gutter="10" class="platform-columns">
              <el-col
                :xs="24"
                :sm="12"
                :md="8"
                v-for="data in platformFollowers"
                :key="data.platform"
                class="mb-3"
              >
                <FanbasePlatform
                  :followers="
                    data.dataFound
                      ? formatNumberIntoHumanizeForm(data.followers)
                      : 'N/A'
                  "
                  :platform="
                    data.platform === 'youtube_channel'
                      ? 'youtube'
                      : data.platform
                  "
                  :has-data="data.dataFound"
                  :indicator="capitalizeFirstLetter(data.field)"
                />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getArtistFanbaseFollowersDistributionV2FromMongo } from "@/api/mongoDB";
import DonutChart from "./DonutChart";
import FanbasePlatform from "./FanbasePlatform";
import CardToolbar from "../../common/components/CardToolbar";
import EmptyCardLoading from "@/common/components/EmptyCardLoading";
import { useRoute } from "vue-router";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";
import { capitalizeFirstLetter } from "@/utils/staticHelper";

export default {
  components: { CardToolbar, FanbasePlatform, DonutChart, EmptyCardLoading },
  setup() {
    const loading = ref(false);
    const series = ref([]);
    const labels = ref([]);
    const platformFollowers = ref([]);
    const colours = ref([]);
    const route = useRoute();

    onMounted(async () => {
      if (route.params.spotifyID) {
        await fetchFanbaseDistribution(route.params.spotifyID);
      }
    });

    async function fetchFanbaseDistribution(spotifyID) {
      loading.value = true;
      try {
        const { data } = await getArtistFanbaseFollowersDistributionV2FromMongo(
          spotifyID
        );
        platformFollowers.value = data.platformFollowersDTOS;
        if (platformFollowers.value.length > 0) {
          for (const responseElement of platformFollowers.value) {
            colours.value.push(responseElement.colour);
          }
        }
        series.value = data.apexChartDTO.yAxis.filter(
          (value) => value !== null
        );
        labels.value = data.apexChartDTO.xAxis.map((value) =>
          capitalizeFirstLetter(value)
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    return {
      loading,
      series,
      labels,
      platformFollowers,
      colours,
      formatNumberIntoHumanizeForm,
      capitalizeFirstLetter,
    };
  },
};
</script>

<style lang="scss" scoped></style>
