<template>
  <div class="mt-2 artist-spotify-section" id="artist_spotify_section">
    <div id="spotify_monthly_listeners_by_country">
      <CardToolbar
        description="The distribution of Artist's monthly listeners across countries"
        title="Monthly Listeners by Country"
        :show-toolbar-buttons="false"
        :margin="true"
        :font-style="true"
        :padding-left="true"
        :padding="true"
        :shadow-class="true"
        v-if="
          monthlyListeners.countries.length > 0 &&
          monthlyListeners.followers.length > 0 &&
          monthlyListenersChartData.countries.length > 0 &&
          monthlyListenersChartData.followers.length > 0
        "
      >
        <template v-slot:body>
          <el-row>
            <el-col :xs="24" :lg="12">
              <ListenersTable
                :table-styling="true"
                :data="monthlyListeners"
                :table-headers="tableHeaders"
              />
            </el-col>
            <el-col :xs="24" :lg="12">
              <BarGraph :data="monthlyListenersChartData" title="" />
            </el-col>
          </el-row>
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import ListenersTable from "@/artists/components/ListenersTable.vue";
import BarGraph from "@/artists/components/BarGraph.vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import { ref, watch } from "vue";

export default {
  name: "SpotifyV2",
  components: {
    CardToolbar,
    ListenersTable,
    BarGraph,
  },
  props: {
    listenersByCountry: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const monthlyListeners = ref({ countries: [], followers: [] });
    const monthlyListenersChartData = ref({ countries: [], followers: [] });
    const tableHeaders = ["Rank", "Country", "Listeners"];
    const createResponse = (array) => {
      const xAxis = [];
      const yAxis = [];
      for (const data of array) {
        xAxis.push(data.code2);
        yAxis.push(data.listeners);
      }
      monthlyListeners.value = {
        countries: xAxis,
        followers: yAxis,
      };
      monthlyListenersChartData.value = {
        countries: xAxis.length > 10 ? xAxis.slice(0, 10) : xAxis,
        followers: yAxis.length > 10 ? yAxis.slice(0, 10) : yAxis,
      };
    };
    watch(
      () => props.listenersByCountry,
      (array) => {
        if (array.length > 0) {
          createResponse(props.listenersByCountry);
        }
      },
      { immediate: true }
    );
    return {
      monthlyListeners,
      monthlyListenersChartData,
      tableHeaders,
    };
  },
};
</script>
